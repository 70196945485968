import React from 'react';
import { useHistory } from 'react-router-dom';

import { parseEther } from '@ethersproject/units';
import useNftDaoFactoryContract from '../hooks/useNftDaoFactoryContract';
import { useWeb3React } from '@web3-react/core';
import getReceipt from '../lib/getReceipt';
import { addToast } from '../hooks/useToast';
import { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { isAddress } from '@ethersproject/address';
import { Contract } from '@ethersproject/contracts';
// import { useRouter } from 'next/router';
import ERC721_ABI from '../constants/abi/erc721.json';
import { InfuraProvider } from '@ethersproject/providers';
import {
   AVERAGE_BLOCK_TIME_IN_SECS,
   INFURA_ID,
   INFURA_NETWORK,
   NFT_DAO_ADDRESS,
   ETHERSCAN_KEY
} from '../constants';

// import Select from 'react-select';

const Tokenize = () => {
   const contract = useNftDaoFactoryContract();

   const { account, library } = useWeb3React();

   const library_infura = new InfuraProvider(INFURA_NETWORK, INFURA_ID);

   // const [first_loop, set_first_loop] = useState(0);

   //constructor arguments
   const [tokenName, setTokenName] = useState('');
   const [tokenSymbol, setTokenSymbol] = useState('');
   const [tokenSupply, setTokenSupply] = useState('');

   const [nftAddress, setNftAddress] = useState('');
   const [nftId, setNftId] = useState('');

   const [buyoutPrice, setBuyoutPrice] = useState('');

   // const [unlocktext, set_unlocktext] = useState('Please Unlock Wallet');

   const [isapproved, setisapproved] = useState(false);
   const [iserror, setiserror] = useState(false);

   const [locktime, setlocktime] = useState(0);
   const [votingtime, setvotingtimr] = useState(0);

   const [selectedimage, setselectedimage] = useState('');
   const [usernftarray, setusernftarray] = useState([]);
   const [checkedNfts, setCheckedNfts] = useState([]);
   const [selectedNFTs, setSelectedNFTs] = useState([]);
   const [tokenizationForm, setTokenizationForm] = useState(false);
   const [manualInput, setManualInput] = useState(false);
   const [imageID, setImageID] = useState('');

   // const [selectedname, setselectedname] = useState('');

   const history = useHistory();

   // effect hook for updating data
   useEffect(() => {
      async function checkusernfts() {
         let res;
         let json;
         let check_array = {};

         let turl =
            'https://api-rinkeby.etherscan.io/api?module=account&action=tokennfttx&address=' +
            account +
            '&startblock=0&endblock=999999999&sort=asc&apikey=' +
            ETHERSCAN_KEY;
          console.log(turl);

         try {
            res = await fetch(turl);
            json = await res.json();

            if (json.status === '1' && json.message === 'OK') {
               let res_array = json.result;

               let i;
               for (i = 0; i < res_array.length; i++) {
                  // if (res_array[i].to.toLowerCase() === account.toLowerCase()) {

                  if (
                     res_array[i].to.toLowerCase() ===
                     account.toLowerCase()
                  ) {
                     check_array[
                        res_array[i].contractAddress +
                           ' ' +
                           res_array[i].tokenID
                     ] =
                        res_array[i].tokenName +
                        '(' +
                        res_array[i].tokenSymbol +
                        ')' +
                        ' ID: ' +
                        res_array[i].tokenID;
                     console.log('add');
                  }
                   if (res_array[i].to.toLowerCase() !== account.toLowerCase()) {
                      check_array[
                         res_array[i].contractAddress +
                            ' ' +
                            res_array[i].tokenID
                      ] = 0;
                      console.log('min');
                   }
               }
            }
         } catch (e) {
            console.log(e);
         }

         // console.log(check_array);
         let temp_array = [];

         for (let key in check_array) {
            // console.log(key, check_array[key]);

            if (check_array[key] !== 0) {
               let temper = {
                  label: check_array[key],
                  value: key,
                  checked: false
               };
               temp_array.push(temper);
            }
         }
         console.log(temp_array);
         setusernftarray(temp_array);
         setCheckedNfts(temp_array);
      }

      if (account) {
         checkusernfts();
      }
   }, [account, library]);

   // useEffect for clearing selected NFT's on usernftarray change
   useEffect(() => {
      setSelectedNFTs([]);
   }, [usernftarray]);

   useEffect(() => {
      if (isapproved) setTokenizationForm(true);
   }, [isapproved]);

   function handleChangeTokenName(event) {
      const values = event.target.value;
      setTokenName(values);
   }

   function handleChangeTokenSymbol(event) {
      const values = event.target.value;
      setTokenSymbol(values);
   }

   function handleChangeTokenSupply(event) {
      const values = event.target.value;
      setTokenSupply(values);
   }

   const toggleNft = index => {
      let arr = [...checkedNfts];

      arr[index].checked = !arr[index].checked;
      if (arr[index].checked) {
         setSelectedNFTs([...selectedNFTs, arr[index]]);
      } else if (!arr[index].checked) {
         let filteredArr = selectedNFTs.filter(
            nft => arr[index].label !== nft.label
         );
         setSelectedNFTs(filteredArr);
      }
      setCheckedNfts(arr);
   };

   const manuallyAddHandler = (e, nftAddress, nftId) => {
      e.preventDefault();
      let index;
      let matchedNft;
      usernftarray.forEach((nft, i) => {
         if (nft.value === `${nftAddress} ${nftId}`) {
            matchedNft = nft;
            index = i;
         }
      });

      if (matchedNft) {
         toggleNft(index);
         onNftSelectChange(matchedNft);
      }

      console.log(matchedNft, index);
   };

   function handleChangeBuyoutPrice(event) {
      const values = event.target.value;
      setBuyoutPrice(values);
   }

   function handleChangeLockTime(event) {
      const values = event.target.value;
      setlocktime(values);
   }

   function handleChangeVotingTime(event) {
      const values = event.target.value;
      setvotingtimr(values);
   }

   async function onNftSelectChange(event) {
      setImageID(event.label.split('ID: ')[1]);
      let valuer = event['value'].split(' ');
      setNftAddress(valuer[0]);
      setNftId(valuer[1]);
      setisapproved(false);
      setiserror(false);

      try {
         const erc721_cont =
            isAddress(valuer[0]) && !!ERC721_ABI && !!library_infura
               ? new Contract(valuer[0], ERC721_ABI, library_infura)
               : undefined;
         const turl = await erc721_cont.tokenURI(valuer[1]);
         console.log(turl);

         let res = await fetch(turl);
         let json = await res.json();
         let imager = '';

         try {
            imager = json.image;
         } catch {}

         if (imager && imager.startsWith('ipfs:')) {
            // console.log(json.image);
            imager = 'https://gateway.pinata.cloud/' + imager.substring(7);
         } else {
            try {
               imager = json.result.data.big_image;
            } catch {}
         }

         if (selectedimage === imager) return;
         // console.log(imager);
         setselectedimage(imager);
      } catch (e) {
         setselectedimage('');
      }

      // try {
      //    const erc721_cont =
      //       isAddress(valuer[0]) && !!ERC721_ABI && !!library_infura
      //          ? new Contract(valuer[0], ERC721_ABI, library_infura)
      //          : undefined;
      //    const turl = await erc721_cont.tokenURI(valuer[1]);
      //    let res = await fetch(turl);
      //    let json = await res.json();
      //    // setselectedname(json.name);
      // } catch (e) {
      //    setselectedname('');
      // }
   }

   async function approveNft() {
      if (!account) {
         addToast({ body: 'Please Unlock Your Wallet', type: 'error' });
         return;
      }

      console.log(selectedNFTs)


      let i;
      for (i=0; i < selectedNFTs.length ; i++) {
         console.log(selectedNFTs[i].value)

         let splitter = selectedNFTs[i].value.split(" ")

         const erc721_cont =
             isAddress(splitter[0]) && !!ERC721_ABI && !!library
                 ? new Contract(splitter[0], ERC721_ABI, library.getSigner(account))
                 : undefined;

         try {
            const value = await erc721_cont.getApproved(splitter[1]);
            console.log(value);

            if (value !== NFT_DAO_ADDRESS) {
               try {
                  const { hash } = await erc721_cont.approve(NFT_DAO_ADDRESS, splitter[1]);
                  await getReceipt(hash, library);
               } catch (e) {
                  addToast({ body: e.message, type: 'error' });
               }
            }


         } catch (e) {
            addToast({
               body: 'There was an error checking the approvals of the NFT token - But dont panic could be because of an older NFT implementation you can try to approve still',
               type: 'error'
            });
         }
      }
   }

   // nft dao call contract
   const callNftDao = async () => {
      const timelocktime = locktime;
      const governorvotingtime = votingtime;

      if (timelocktime < 0) {
         addToast({
            body: 'Please enter a valid timelock time in day (greater or equal 0)',
            type: 'error'
         });
         return;
      }

      if (governorvotingtime <= 0) {
         addToast({
            body: 'Please enter a valid voting time in day (greater 0)',
            type: 'error'
         });
         return;
      }

      try {
         const amount_eth = parseEther(buyoutPrice);
         console.log(contract);

         contract.once('NewAlchemy', (address, event) => {
            console.log('Contract event');
            console.log(address);
            history.push(`/dao?address=${address}`);
         });
         let govtime = parseInt(
            (parseFloat(governorvotingtime) * 24 * 60 * 60) /
               AVERAGE_BLOCK_TIME_IN_SECS
         );
         let locktime = parseInt(parseFloat(timelocktime) * 24 * 60 * 60);

         console.log(govtime);
         console.log(locktime);

         let nftaddressarray = []
         let nftidarray = []

         let i;
         for (i=0; i < selectedNFTs.length ; i++) {
            console.log(selectedNFTs[i].value)

            let splitter = selectedNFTs[i].value.split(" ")
            nftaddressarray.push(splitter[0])
            nftidarray.push(splitter[1])
         }

         addToast({
            body:
               'Voting time will be set to ' +
               govtime +
               ' blocks and ' +
               'Timelock delay will be set to ' +
               locktime +
               ' seconds',
            type: 'info'
         });

         const { hash } = await contract.NFTDAOMint(
            nftaddressarray,
            account,
            nftidarray,
            BigNumber.from('1000000000000000000').mul(tokenSupply),
            tokenName,
            tokenSymbol,
            amount_eth,
            govtime,
            locktime
         );
         await getReceipt(hash, library);
      } catch (e) {
         addToast({ body: e.message, type: 'error' });
      }
   };

   return (
      <>
         <div className='tokenize'>
            <div className='tokenize__container'>
               <h3 className='tokenize__header'>Tokenize NFT's</h3>
               {!tokenizationForm && (
                  <div className='tokenize__container--flex-wrapper'>
                     <div className='tokenize__container--flex-wrapper--secondary'>
                        <div className='tokenize__container--select card-shadow'>
                           <h4 className='tokenize__container--select-label'>
                              {manualInput
                                 ? 'Manually add your NFT'
                                 : `Select your NTF's below`}
                           </h4>
                           <h4
                              className='tokenize__container--select-manual'
                              onClick={() =>
                                 account && setManualInput(!manualInput)
                              }
                           >
                              {!manualInput
                                 ? 'Input manually'
                                 : 'Input from list'}
                           </h4>
                           {!manualInput ? (
                              <div
                                 className={
                                    !tokenizationForm
                                       ? 'tokenize__container--select-secondary'
                                       : 'tokenize__container--select-secondary disabled'
                                 }
                              >
                                 {!account && (
                                    <h4 className='tokenize__container--select-secondary--connect'>
                                       Connect Wallet to Continue
                                    </h4>
                                 )}
                                 {checkedNfts.map((nft, index) => (
                                    <div
                                       className='tokenize__select-component'
                                       key={index}
                                       onClick={() =>
                                          !tokenizationForm &&
                                          onNftSelectChange(checkedNfts[index])
                                       }
                                    >
                                       <button
                                          onClick={() => {
                                             if (tokenizationForm) return;
                                             toggleNft(index);
                                          }}
                                          className='tokenize__select-component--checkbox'
                                       >
                                          {checkedNfts[index].checked && 'X'}
                                       </button>
                                       <p className='tokenize__select-component--text'>
                                          {nft.label}
                                       </p>
                                    </div>
                                 ))}
                              </div>
                           ) : (
                              <form
                                 onSubmit={e => e.preventDefault}
                                 className={
                                    !tokenizationForm
                                       ? 'tokenize__container--select-manual-form form'
                                       : 'tokenize__container--select-manual-form form disabled'
                                 }
                              >
                                 <h3 className='form__label'>NFT Address:</h3>
                                 <input
                                    onChange={e =>
                                       setNftAddress(e.target.value)
                                    }
                                    value={nftAddress}
                                    type='text'
                                    required
                                    className='form__input'
                                 />

                                 <h3 className='form__label'>NFT ID:</h3>
                                 <input
                                    onChange={e => setNftId(e.target.value)}
                                    value={nftId}
                                    type='text'
                                    required
                                    className='form__input'
                                 />
                                 <button
                                    className='tokenize__container--select-manual-form--button form__button'
                                    onClick={e =>
                                       manuallyAddHandler(e, nftAddress, nftId)
                                    }
                                 >
                                    ADD NFT
                                 </button>
                              </form>
                           )}
                        </div>

                        {account && !tokenizationForm && (
                           <button
                              className='tokenize__form--button card-shadow'
                              onClick={() => {
                                 if (selectedNFTs.length > 0) {
                                    approveNft()
                                    console.log(checkedNfts)
                                    console.log(selectedNFTs)
                                    setTokenizationForm(true);
                                 }
                              }}
                           >
                              {selectedNFTs.length > 0
                                 ? `APPROVE NFT'S`
                                 : `SELECT NFT'S TO CONTINUE`}
                           </button>
                        )}
                     </div>
                     <div className='tokenize__container--flex-wrapper--secondary-image card-shadow'>
                        <div className='tokenize__container--image '>
                           {selectedimage ? (
                              <img alt='NFT' src={selectedimage}></img>
                           ) : (
                              <div className='tokenize__container--image-flex'>
                                 <div className='tokenize__container--image-flex--placeholder' />
                                 <h4 className='tokenize__container--image--label'>
                                    If available NFT image will be displayed
                                    here
                                 </h4>
                              </div>
                           )}
                        </div>
                        <div className='tokenize__container--image-info-container'>
                           <h4 className='tokenize__container--image-info-container--selected tokenize__container--image-info-container--selected-first'>
                              {`ID: ${imageID.slice(0, 10)}${
                                 imageID.length > 10 ? '...' : ''
                              }`}
                           </h4>
                           <h4 className='tokenize__container--image-info-container--selected'>
                              {`${usernftarray.length} NFT's owned`}
                           </h4>
                           <h4 className='tokenize__container--image-info-container--selected'>
                              {`${selectedNFTs.length} selected`}
                           </h4>
                        </div>
                     </div>
                  </div>
               )}
               {tokenizationForm && (
                  <form
                     className='tokenize__NTF-form'
                     onSubmit={e => e.preventDefault()}
                  >
                     <h4
                        className='tokenize__container--select-manual'
                        onClick={() => {
                           setTokenizationForm(false);
                           setisapproved(false);
                        }}
                     >
                        Go back
                     </h4>
                     <div className='tokenize__NFT-form--flex-div'>
                        <div>
                           <h3 className='form__label'>Token Name:</h3>
                           <input
                              type='text'
                              required
                              className='form__input'
                              onChange={e => handleChangeTokenName(e)}
                           />
                        </div>

                        <div>
                           <h3 className='form__label'>Token Symbol:</h3>
                           <input
                              type='text'
                              required
                              className='form__input'
                              onChange={e => handleChangeTokenSymbol(e)}
                           />
                        </div>

                        <div>
                           <h3 className='form__label'>Total Supply:</h3>
                           <input
                              type='number'
                              required
                              className='form__input'
                              onChange={e => handleChangeTokenSupply(e)}
                           />
                        </div>
                     </div>

                     <div className='tokenize__NFT-form--flex-div'>
                        <div>
                           <h3 className='form__label'>Buyout Price in ETH:</h3>
                           <input
                              type='number'
                              required
                              className='form__input'
                              onChange={e => handleChangeBuyoutPrice(e)}
                           />
                        </div>
                        <div>
                           <h3 className='form__label'>
                              Voting Period in Days:
                           </h3>
                           <input
                              type='number'
                              required
                              className='form__input'
                              onChange={e => handleChangeVotingTime(e)}
                           />
                        </div>
                        <div>
                           <h3 className='form__label'>Timelock in Days:</h3>
                           <input
                              type='number'
                              required
                              className='form__input'
                              onChange={e => handleChangeLockTime(e)}
                           />
                        </div>
                     </div>
                  </form>
               )}
            </div>
            {account && tokenizationForm && (
               <button
                  className='tokenize__form--button card-shadow'
                  onClick={callNftDao}
               >
                  TOKENIZE
               </button>
            )}
         </div>
      </>
   );
};

export default Tokenize;
