export const ACTIVE_NETWORK = 4;
export const INFURA_ID = '6b5a41304d2443c887a77866d35b249f';
export const ETHERSCAN_KEY = 'RC2K3GMCK38RSTFN5ZS97UTT39WXJ3WWCE';

export const DUTCH_FACTORY = '0x67eb6AaB950c8133780516950Cb25106613C3dB6';
export const PINATA_API_KEY = 'f957939f2b22918a89b9';
export const PINATA_SECRET =
   '7dd4d5fdde247961941e8d8f2fbace35c34aadd4581c2c5ecf1acbdb98dc0f11';
export const MINTY_FACTORY = '0x68F18451Aa3615438Bb84Afbf2CA87Ebc7F068C4';

// config old
export const NFT_DAO_ADDRESS = '0x92ae0F6ee16916493063085cB59b5F4b9E0DEE6b';
export const ALC_ADDRESS = '0x3a3388c9a29741482fef5da1d8a297bdc1d45215';
export const STAKINGPOOL_LP = '0x6d46fb5527bDb6Dcb2716f0FE1F0EA0F9ae019fC';
export const UNIV2_STAKING_ADDRESS =
   '0xfb0997696919ba43275f4156f9550b9f68f5edfe';
export const INFURA_NETWORK = 'rinkeby';


export const AVERAGE_BLOCK_TIME_IN_SECS = 15;
export const PROPOSAL_LENGTH_IN_BLOCKS = 5760;
export const PROPOSAL_LENGTH_IN_SECS =
   AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS;

export const GARDEN_OPTIONS = [
   {
      tokenName: 'ALCH',
      rewardName: 'ETH',
      tokenGardenName: 'ALCH Fee Pool',
      tokenIconPath: 'alchemy_square.png',
      tokenIconPathReward: 'eth.png',
      descriptionTop: 'Deposit ALCH, earn ETH',
      poolAddress: '0x82E025bce8f4707b7AD406c036E5b8273389612B',
      tokenAddress: '0x0000A1c00009A619684135B824Ba02f7FbF3A572',
      open: true
   },
   {
      tokenName: 'ALCH-ETH LP',
      rewardName: 'ALCH',
      tokenGardenName: 'LP Staking Pool',
      tokenIconPath: 'alchemy_square.png',
      tokenIconPathReward: 'alchemy_square.png',
      descriptionTop: 'Deposit ALCH-ETH LP, earn ALCH',
      poolAddress: '0x6d46fb5527bDb6Dcb2716f0FE1F0EA0F9ae019fC',
      tokenAddress: '0xfb0997696919ba43275f4156f9550b9f68f5edfe',
      open: true
   }
];
